import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEinHausInOesterreich = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet ein Haus in Österreich?" showCalc={false} />
            <Article>
                <p>
                    Haus, der Traum! Auf der Suche nach dem passenden Eigenheim ist vor allem eines wichtig: sich
                    möglichst früh einen Überblick über das Angebot zu verschaffen und ein Gespür für regionale
                    Unterschiede auf dem Markt zu entwickeln. Bei beidem hilft dir der folgende Artikel.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Österreich – die aktuelle Tendenzen</h2>
                <p>
                    Wenn du am Kauf eines Hauses interessiert bist, kommst du natürlich nicht daran vorbei, dich mit den
                    aktuellen Entwicklungen auf dem Immobilienmarkt zu beschäftigen. Im Folgenden haben wir die
                    wichtigsten Informationen für dich zusammengefasst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Die derzeitige Situation</h2>
                <p>
                    Aufgrund der aktuell günstigen Kreditzinsen liegt der Anstieg der Immobilienpreise in Österreich
                    seit dem vierten Quartal 2020 im zweistelligen Bereich. 12,3 % betrug dieser Anstieg im ersten
                    Quartal 2021, im zweiten war eine Teuerung von 11,7 % zu verzeichnen. Während sich der Anstieg der
                    Kaufpreise für Einfamilienhäuser außerhalb Wiens 2021 von 12,9 % im ersten auf 11,3 % im zweiten
                    Quartal verringerte, wurden gebrauchte Eigentumswohnungen in letzterem um 14,3 % teurer. Beobachten
                    lässt sich zudem, dass die Immobilienpreise außerhalb Wiens im Allgemeinen schneller wachsen als
                    innerhalb der Landeshauptstadt: Die Preise in Wien stiegen im ersten Quartal 2021 um 10,9, im
                    zweiten um 10,7 %. In den anderen Bundesländern kam es in diesem Zeitraum hingegen zu Wachstumsraten
                    von 14 bzw. 12,8 %. Dieser Trend gilt im Besonderen für den Neubauwohnungsmarkt: Stiegen die Preise
                    für neue Immobilien in Wien 2021 um 10,4 im ersten und von 10, 1 % zweiten Quartal, so kam es in den
                    übrigen Bundesländern zu einem Anstieg von 16,7 und 12,9 %.
                </p>
                <hr />

                <h2>Prognose für 2022</h2>
                <p>
                    Ausgehend von den skizzierten Entwicklungen rechnet man damit, dass der Anstieg der Immobilienpreise
                    insbesondere in der weiteren Umgebung von Ballungszentren anhält. In besonderem Maße gilt dies für
                    das rurale Gebiet, wo eine zunehmende Knappheit des Baugrundes die Preise zusätzlich in die Höhe
                    treibt. Es gibt allerdings auch Regionen, in denen das Preisniveau, das die Kaufkraft der
                    Bewohner*innen allmählich zu übersteigen droht, zu sinken beginnt. Das ist beispielsweise in
                    Innsbruck oder Salzburg der Fall. Der allgemeine Trend zum Preisanstieg bleibt von diesen regionalen
                    Phänomenen allerdings vorerst unberührt. Erst ein deutlicher Anstieg des Zinsniveaus könnte das
                    Wachstum im Laufe mehrerer Jahre bremsen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Das kosten Häuser in den einzelnen Bundesländern</h2>
                <p>
                    Nun sehen wir uns an, mit welchen Preisen du beim Hauskauf in den einzelnen Bundesländern rechnen
                    musst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>

                <h3>Wien</h3>
                <p>
                    Ein Hauskauf kommt in der Bundeshauptstadt für die meisten Personen lediglich in den äußeren
                    Bezirken in Frage. Im 11. Bezirk musst du beispielsweise mit einem Kaufpreis von ca. 1.926 € pro
                    Quadratmeter rechnen. Etwas teurer fallen Häuser im 22. Bezirk aus: Dort sind es 2.305,83 €, die du
                    für einen Quadratmeter bezahlen musst.
                </p>

                <h3>Niederösterreich</h3>
                <p>
                    Wenn wir einen Blick in das angrenzende Niederösterreich werfen, dann finden wir nicht nur saftige
                    Wiesen und Weinberge, sondern auch günstigere Immobilien. Im urbanen Gebiet der Landeshauptstadt St.
                    Pölten kostet ein Quadratmeter Wohnfläche deines zukünftigen Hauses etwas 1.586,25 €. Noch etwas
                    günstiger wirst du in Korneuburg mit einem Quadratmeterpreis von 1.547,89 € fündig. Ähnlich viel
                    bezahlst du im Stadtgebiet von Wiener Neustadt: 1.534,68 € beträgt der Preis für einen Quadratmeter
                    hier. Ein wiederum billigere Option ist Hollabrunn, wo du beim Kauf eines Hauses 1.295,56 € pro
                    Quadratmeter bezahlen musst. Ein besonders erschwingliche Wahl ist ein Haus in Waidhofen an der
                    Thaya: Der Preis pro Quadratmeter liegt hier bei 896,79 €.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>

                <h3>Burgenland</h3>
                <p>
                    Im Unterschied zu Niederösterreich ist es im Burgenland nicht die Hauptstadt, die den höchsten
                    Quadratmeterpreis aufweist. Während du in Eisenstadt (Stadt) 1.437,88 € bezahlst, kostet dich ein
                    Quadratmeter Eigenheim in Neusiedl am See 1.806,11 €. Etwas günstiger ist der urbane Teil des
                    ebenfalls in Seenähe gelegenen Rust mit 1.317,75 €. Ebenfalls billiger sind die Orte Mattersburg und
                    Güssing mit 1.040,33 bzw. 1.110 € pro Quadratmeter.
                </p>

                <h3>Oberösterreich</h3>
                <p>
                    Der Kaufpreis eines Einfamilienhauses im städtischen Bereich von Linz beträgt 1.868,56 € pro
                    Quadratmeter. Etwas günstiger steigst du beim Hauskauf in Wels (Stadt) aus, wo du mit einem Preis
                    von 1.594,74 pro Quadratmeter rechnen musst. Vöcklabruck ist mit einem Quadratmeterpreis von
                    1.506,83 schon erheblich billiger. Braunau liegt zwar am Inn, ist aus bekannten Gründen allerdings
                    weniger in, was sich zumindest positiv auf den Immobilienpreis auswirkt: 1.289,00 € kostet dich ein
                    Quadratmeter beim Hauskauf dort.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>

                <h3>Salzburg</h3>
                <p>
                    Erheblich teurer sind Häuser in Salzburg. Besonders hoch ist der Quadratmeterpreis in der der
                    Innenstadt: Er beträgt 2.783,48 €. Immer noch deutlich über dem Preis der Wiener Außenbezirke liegt
                    Zell am See mit 2.524,78 € und auch in Hallein kann man sich mit einem Preis von 2.508,42 € pro
                    Quadratmeter locker mit der Bundeshauptstadt messen. Das gilt auch für St. Johann im Pongau, wo dich
                    ein Betrag von 2.402 € pro Quadratmeter erwartet. Der Quadratmeterpreis in Höhe von 1.812,33 € in
                    Tamsweg wirkt da fast schon wie ein Schnäppchen.
                </p>

                <h3>Tirol</h3>
                <p>
                    Der Immobilienmarkt in Tirol kann sogar die Preise im Zentrum Salzburgs toppen, zahlt man beim
                    Hauskauf in Innsbruck derzeit doch 3.045,00 € pro Quadratmeter. Noch mehr ist es in Kitzbühel:
                    Planst du, dich durch den Erwerb eines Hauses in die Österreichische Schickeria einzukaufen, dann
                    musst du tief in die Tasche greifen. Im beliebten Tourismusgebiet erwartet dich ein
                    Quadratmeterpreis von 3.245,13 €. Auch die Preise in Kufstein (2.365,00 €) und Landeck (2.292,56 €)
                    sind nicht gerade niedrig. Eine günstigere, aber etwas abgelegener Option ist Reutte, wo 1.513,67 €
                    pro Quadratmeter fällig werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>

                <h3>Steiermark</h3>
                <p>
                    Der steirische Immobilienmarkt zeichnet sich durch ein besonders starkes Stadt-Land-Gefälle aus: Die
                    Kaufpreise von Einfamilienhäusern sind in Graz etwa doppelt so hoch wie im Umland: 2.131,88 € zahlst
                    du in der Hauptstadt pro Quadratmeter, während es etwa in Murau lediglich 844 € sind. Dazwischen
                    liegen beispielsweise Weiz mit 1.450, Leibnitz mit 1.362,50 und Leoben mit 935 € pro Quadratmeter.
                </p>

                <h3>Vorarlberg</h3>
                <p>
                    In Vorarlberg steigen die Preise von Häusern derzeit deutlich schneller als die von neuen
                    Eigentumswohnungen. In Bregenz liegt der Quadratmeterpreis bei 3.213,26 €. Feldkirch (2.936,40 €)
                    und Dornbirn (2.932,72 €) liefern sich ein regelrechtes Kopf-an-Kopf-Rennen. Bludenz liegt mit
                    2.766,52 € pro Quadratmeter ebenfalls nicht weit zurück.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>

                <h3>Kärnten</h3>
                <p>
                    Weniger rasant steigen die Immobilienpreise im südlichsten Bundesland. In Klagenfurt (Stadt)
                    verzeichnet man im Falle von Einfamilienhäusern derzeit einen Quadratmeterpreis von 1.468,21 €. Ein
                    ähnlich hohes Preisniveau weist das Stadtgebiet von Villach mit 1.327,73 € auf. Etwas günstiger sind
                    Häuser in Feldkirchen mit 1.169,84 und Wolfsberg mit 1.206,25 € pro Quadratmeter.
                </p>

                <h3>Fazit</h3>
                <p>
                    Der Immobilienmarkt in Österreich weist teilweise starke regionale Unterschiede auf. Das ist für
                    dich als Käufer*in nicht nur gut zu wissen, du kannst diesen Umstand auch zu deinem Vorteil nutzen.
                    Arbeitest du etwa in Wien oder einer anderen größeren Stadt, so kann es durchaus von Vorteil sein,
                    sich in angrenzenden Regionen bzw. Bundesländern nach einem Eigenheim umzusehen. Wenn die
                    öffentliche Verbindung stimmt, kannst du so auch die Vorzüge einer Großstadt mit den oft niedrigeren
                    Quadratmeterpreisen im Umland verbinden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEinHausInOesterreich"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEinHausInOesterreich"}
                heading={"Was kostet ein Haus in Österreich?"}
            />
        </Layout>
    );
};

export default WasKostetEinHausInOesterreich;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-ein-haus-in-oesterreich", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
